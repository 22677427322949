import {
    Waveform,
    constants,
    Spinner,
    Typography,
} from '@epidemicsound/design-system'
import WaveformPainter from '@epidemicsound/design-system/dist/types/components/Media/Waveform/WaveformPainter'
import React, { FC, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { formatSecondsToMinutesAndSeconds } from '../../utils'

const { Text } = Typography

const { COLORS } = constants

const StyledWaveformWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-basis: 30rem;
    margin-left: 1rem;
`

const StyledDurationText = styled(Text)`
    display: flex;
    margin-left: 1rem;
`

const StyledElapsedTimeText = styled(Text)`
    margin-right: 1rem;
    display: flex;
`

interface ProgressWaveformProps {
    elapsedTime: number
    duration: number
    waveformData: number[]
    onSeekTo: (seekToSeconds: number) => void
}

interface ProgressWaveformWrapperProps {
    url: string
    elapsedTime: number
    duration: number
    onSeekTo: (seekToSeconds: number) => void
    isLoading: boolean
}

export const ProgressWaveformWrapper: FC<ProgressWaveformWrapperProps> = ({
    elapsedTime,
    duration,
    url,
    onSeekTo,
    isLoading,
}) => {
    const [waveformData, setWaveformData] = useState<number[]>()

    const loadWaveformDataCallback = useCallback(() => {
        fetch(url).then((response) => {
            response.json().then((json) => {
                setWaveformData((json as WaveformData).data)
            })
        })
    }, [])

    useEffect(() => loadWaveformDataCallback(), [])

    const displayTime = (sec: number) =>
        formatSecondsToMinutesAndSeconds(Math.floor(sec))

    return (
        <StyledWaveformWrapper>
            <StyledElapsedTimeText component="div">
                {displayTime(elapsedTime)}
            </StyledElapsedTimeText>

            {(waveformData && !isLoading && (
                <ProgressWaveform
                    elapsedTime={elapsedTime}
                    duration={duration}
                    waveformData={waveformData}
                    onSeekTo={onSeekTo}
                />
            )) || <Spinner size={24} />}

            <StyledDurationText component="div" color={COLORS.GRAY_2}>
                {displayTime(duration)}
            </StyledDurationText>
        </StyledWaveformWrapper>
    )
}

export interface WaveformData {
    version: number
    channels: number
    example_rate: number
    samples_per_pixel: number
    bits: number
    length: number
    data: number[]
}

export const ProgressWaveform: FC<ProgressWaveformProps> = ({
    elapsedTime,
    duration,
    waveformData,
    onSeekTo,
}) => {
    const progress = elapsedTime / duration

    return (
        <>
            <Waveform
                height={constants.WAVEFORM_HEIGHT}
                fillColor={COLORS.GRAY_2}
                strokeColor={COLORS.GRAY_2}
                waveformData={waveformData}
                onLoad={(waveform: WaveformPainter) =>
                    waveform.drawProgress(progress)
                }
                onClick={(e: React.MouseEvent) => {
                    const seekToSecond =
                        (e.nativeEvent.offsetX / e.currentTarget.clientWidth) *
                        duration
                    onSeekTo(seekToSecond)
                }}
            />
        </>
    )
}
