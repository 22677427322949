import { FC } from 'react'
import styled from 'styled-components'
import { Image } from 'components/image/Image'

const StyledTableColumnWithImageAndTextContainer = styled.div`
    display: flex;
`

const StyledTextContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    text-align: left;
    margin-left: 1rem;
`

interface TableColumnWithImageAndTextProps {
    image?: string
    imageAlt: string
    onClick?: () => void
    children: React.ReactNode
    className?: string
}

export const TableColumnWithImageAndText: FC<
    TableColumnWithImageAndTextProps
> = ({ image, imageAlt, onClick, children, className }) => {
    return (
        <StyledTableColumnWithImageAndTextContainer onClick={() => onClick?.()}>
            <Image src={image} alt={imageAlt} className={className} />
            <StyledTextContainer>{children}</StyledTextContainer>
        </StyledTableColumnWithImageAndTextContainer>
    )
}
