import { FC } from 'react'
import { Unit } from '../../utils'
import { ImagePlaceHolder } from './ImagePlaceholder'

export interface Dimension {
    width: number
    height: number
    unit?: Unit
}

interface ImageProps {
    src?: string
    alt?: string
    onClick?: () => void
    className?: string
    dimension?: Dimension
}

export const Image: FC<ImageProps> = ({
    src,
    alt,
    onClick,
    className,
    dimension,
}) => {
    const isUrl = src?.includes('http') || src?.includes('https')
    const unit = dimension?.unit || 'px'
    const width = dimension?.width || 50
    const height = dimension?.height || 50
    return (
        <div>
            {isUrl && src && (
                // eslint-disable-next-line @next/next/no-img-element
                <img
                    src={src}
                    alt={alt}
                    onClick={onClick}
                    className={className}
                    width={width + unit}
                    height={height + unit}
                />
            )}
            {!isUrl && (
                <ImagePlaceHolder
                    dimension={dimension}
                    alt={alt}
                    onClick={onClick}
                />
            )}
        </div>
    )
}
