import {
    FC,
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import { MetadataTrackResponse } from 'api/responses'
import { PlaybackStatus } from '../../utils'
import PlayerControlButtons from './PlayerControlButtons'

interface AudioProps {
    currentTime: number
    duration: number
    isLoading: boolean
    progress: number
    seekTo: (seconds: number) => void
    togglePlaybackStatus: () => void
    setVolume: (v: number) => void
    currentVolume: number
    setPlayerStatus: (playerStatus: PlaybackStatus) => void
}

const useAudio = (
    url: string,
    status: PlaybackStatus,
    onStatusChange: (status: PlaybackStatus) => void
): [ReactElement, AudioProps] => {
    // https://www.erikverweij.dev/blog/building-a-minimal-audioplayer/
    const audioRef = useRef<HTMLAudioElement>(null)

    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState(0)
    const [isLoading, setLoading] = useState(true)
    const [currentVolume, setCurrentVolume] = useState(1)

    useEffect(() => {
        // A new url for track was provided!
        setLoading(true)
    }, [url])

    return [
        <audio
            key="player"
            onLoadedData={() => {
                setLoading(false)
                if (audioRef.current) {
                    setDuration(audioRef.current.duration)
                    setCurrentVolume(audioRef.current.volume)
                }
            }}
            src={url}
            ref={audioRef}
            onTimeUpdate={() => {
                audioRef.current && setCurrentTime(audioRef.current.currentTime)
            }}
            onVolumeChange={() => {
                audioRef.current && setCurrentVolume(audioRef.current.volume)
            }}
        />,
        {
            currentTime,
            duration,
            isLoading,
            progress: (currentTime / duration) * 100,
            currentVolume,
            setVolume: (v: number) => {
                if (audioRef.current) audioRef.current.volume = v
            },
            seekTo: (seconds: number) => {
                if (audioRef.current) audioRef.current.currentTime = seconds
            },
            togglePlaybackStatus: async () => {
                switch (status) {
                    case 'play':
                        // Toggle from play -> pause and sync status with active track (track listning)
                        if (audioRef.current) {
                            audioRef.current.pause()
                            onStatusChange('pause')
                        }
                        break
                    case 'pause':
                        // Toggle from pause -> play and sync status with active track (track listning)
                        if (audioRef.current) {
                            await audioRef.current.play()
                            onStatusChange('play')
                        }
                        break
                }
            },
            setPlayerStatus: async (playerStatus: PlaybackStatus) => {
                switch (playerStatus) {
                    case 'play':
                        audioRef.current && (await audioRef.current.play())
                        break
                    case 'pause':
                        audioRef.current && audioRef.current.pause()
                        break
                }
            },
        },
    ]
}

interface PlayerProps {
    status: PlaybackStatus
    track: MetadataTrackResponse
    onStatusChange: (status: PlaybackStatus) => void
    onNext: () => void
}

const Player: FC<PlayerProps> = ({ status, track, onStatusChange, onNext }) => {
    const [audioElement, audioProps] = useAudio(
        track.url,
        status,
        onStatusChange
    )

    const handleSeekTo = (seconds: number) => {
        audioProps.seekTo(seconds)
    }

    const nextTrackCallback = useCallback(() => {
        audioProps.togglePlaybackStatus()
        onNext()
    }, [])

    useEffect(() => {
        // Sync status of player from the play/pause buttons on track listning.
        audioProps.setPlayerStatus(status)
    }, [status])

    useEffect(() => {
        if (
            audioProps.currentTime !== 0 &&
            audioProps.currentTime === audioProps.duration
        ) {
            nextTrackCallback()
        }
    }, [audioProps.currentTime])

    return (
        <div>
            {audioElement}
            <PlayerControlButtons
                track={track}
                elapsedTime={audioProps.currentTime}
                duration={audioProps.duration}
                playbackStatus={status}
                onNextClick={nextTrackCallback}
                onPlayPauseClick={audioProps.togglePlaybackStatus}
                onSeekTo={handleSeekTo}
                isLoading={audioProps.isLoading}
            />
        </div>
    )
}

export default Player
