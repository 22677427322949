import styled from 'styled-components'
import { constants } from '@epidemicsound/design-system'
import { MetadataTrackResponse } from 'api/responses'
import { TableColumnWithImageAndText } from '../simpletable/TableColumnWithImageAndText'
import React, { FC } from 'react'

const StyledGreyDiv = styled.div`
    color: ${constants.COLORS.GRAY_2};
    flex-basis: 100%;
`

const StyledActiveTrackContainer = styled.div`
    max-width: 17rem;
    min-width: 17rem;
    display: flex;
    align-items: center;
`

interface PlayerActiveTrackProps {
    track: MetadataTrackResponse
}

export const PlayerActiveTrack: FC<PlayerActiveTrackProps> = ({ track }) => {
    const trackImage = track ? track.images.XS : undefined

    return (
        <StyledActiveTrackContainer>
            <TableColumnWithImageAndText
                image={trackImage}
                imageAlt={track.title || 'Track artwork'}
            >
                <div>{track.title}</div>
                <StyledGreyDiv>{track?.mainArtists.join(', ')}</StyledGreyDiv>
            </TableColumnWithImageAndText>
        </StyledActiveTrackContainer>
    )
}
