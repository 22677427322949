import React, { FC } from 'react'
import styled from 'styled-components'
import { constants } from '@epidemicsound/design-system'
import { MetadataTrackResponse } from 'api/responses'
import { PlayerActiveTrack } from './PlayerActiveTrack'
import { PlaybackStatus } from '../../utils'
import { ProgressWaveformWrapper } from './ProgressWaveform'
import { Icon } from '@epidemicsound/design-system'

const { COLORS } = constants

interface PlayerControlButtonsProps {
    track: MetadataTrackResponse
    playbackStatus: PlaybackStatus
    onPlayPauseClick: () => void
    onNextClick: () => void
    elapsedTime: number
    duration: number
    onSeekTo: (seekToSeconds: number) => void
    isLoading: boolean
}

const StyledPlayerControlButtons = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    > * {
        margin-right: 1rem;
        min-width: 2rem;
        color: ${COLORS.WHITE};
    }

    i {
        position: relative;
    }

    & svg:focus,
    & svg:hover {
        fill: ${COLORS.GRAY_2};
    }
`

const StyledPlayerControlButtonsContainer = styled.div`
    display: flex;
    align-items: stretch;
    flex-basis: 5rem;
`

const StyledProgressWaveform = styled.div`
    display: flex;
    position: relative;
    align-items: stretch;
    margin-right: 1rem;
`

const PlayerControlButtons: FC<PlayerControlButtonsProps> = ({
    track,
    playbackStatus,
    onPlayPauseClick,
    onNextClick,
    elapsedTime,
    duration,
    onSeekTo,
    isLoading = false,
}) => {
    return (
        <StyledPlayerControlButtonsContainer>
            <StyledPlayerControlButtons>
                {playbackStatus === 'play' ? (
                    <Icon.Pause
                        onClick={onPlayPauseClick}
                        status={playbackStatus}
                        data-cy-player-control-button-pause
                    />
                ) : (
                    <Icon.Play
                        onClick={onPlayPauseClick}
                        status={playbackStatus}
                    />
                )}
                <Icon.Next
                    onClick={onNextClick}
                    data-cy-player-control-button-next
                />
            </StyledPlayerControlButtons>
            <PlayerActiveTrack track={track} />
            {track.waveformUrl && (
                <StyledProgressWaveform>
                    <ProgressWaveformWrapper
                        elapsedTime={elapsedTime}
                        duration={duration}
                        url={track.waveformUrl}
                        onSeekTo={onSeekTo}
                        isLoading={isLoading}
                    />
                </StyledProgressWaveform>
            )}
        </StyledPlayerControlButtonsContainer>
    )
}

export default PlayerControlButtons
