import { TierResponse } from 'api/responses'

export function formatSecondsToMinutesAndSeconds(s: number) {
    return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s
}

export const formatTier = (tier: TierResponse): string => {
    const collectionLabel =
        tier.maxCollections === 1 ? 'Collection' : 'Collections'

    const trackLabel = tier.maxTracksInCollection === 1 ? 'Track' : 'Tracks'

    if (tier.includesPaidOption) {
        const paidCollectionLabel =
            tier.paidMaxCollections === 1 ? 'Collection' : 'Collections'

        const paidTrackLabel =
            tier.paidMaxTracksInCollection === 1 ? 'Track' : 'Tracks'

        return `${tier.name} (${tier.maxCollections} ${collectionLabel}, ${tier.maxTracksInCollection} ${trackLabel},
            ${tier.paidMaxCollections} paid ${paidCollectionLabel}, ${tier.paidMaxTracksInCollection} paid ${paidTrackLabel})`
    }

    return `${tier.name} (${tier.maxCollections} ${collectionLabel}, ${tier.maxTracksInCollection} ${trackLabel})`
}
