import styled from 'styled-components'
import { constants } from '@epidemicsound/design-system'
import { FC } from 'react'
import { Dimension } from './Image'
import { Unit } from '../../utils'

interface StyledImagePlaceHolderProps {
    width?: number
    height?: number
    unit: Unit
}

const StyledImagePlaceHolder = styled.div<StyledImagePlaceHolderProps>`
    width: ${(props: StyledImagePlaceHolderProps) =>
        props.width ? props.width + props.unit : '50px'};
    height: ${(props: StyledImagePlaceHolderProps) =>
        props.height ? props.height + props.unit : '50px'};
    background: ${constants.COLORS.GRAY_2};
    display: flex;
    justify-content: center;
    align-items: center;
`

interface ImagePlaceHolderProps {
    dimension?: Dimension
    alt?: string
    onClick?: () => void
}

export const ImagePlaceHolder: FC<ImagePlaceHolderProps> = ({
    dimension,
    alt,
    onClick,
}) => {
    return (
        <StyledImagePlaceHolder
            width={dimension?.width}
            height={dimension?.height}
            unit={dimension?.unit || 'px'}
            title={alt}
            onClick={onClick}
        />
    )
}
