import styled from 'styled-components'
import { useActiveTrack, usePlayerQueue } from 'hooks/hooks'
import Player from './Player'
import { constants } from '@epidemicsound/design-system'
import { Apps, PlaybackStatus } from '../../utils'
import { FC, useEffect, useState } from 'react'
import { Icon } from '@epidemicsound/design-system'

const StyledPlayerBarContainer = styled.div<PlayerBarProps>`
    min-height: 50px;
    text-align: center;
    padding: 1rem;
    position: fixed;
    bottom: 70px;
    background: ${constants.COLORS.GRAY_1};
    width: 90%;
    z-index: 1100;
    left: 3rem;
    color: ${constants.COLORS.WHITE};
    border-radius: 10px;
    align-items: center;
    display: ${(props: PlayerBarProps) =>
        props.app === 'Landingpage' ? 'none' : 'flex'};
`
export const StyledIcon = styled.i`
    cursor: pointer;

    & svg:focus,
    & svg:hover {
        fill: ${constants.COLORS.GRAY_2};
    }
`
interface PlayerBarProps {
    app: Apps
}

export const PlayerBar: FC<PlayerBarProps> = ({ app }) => {
    const { status, setStatus, activeTrack, stopTrack } = useActiveTrack()
    const { nextTrack } = usePlayerQueue()
    const [isPlayNextTrack, setPlayNextTrack] = useState(false)

    useEffect(() => {
        if (isPlayNextTrack === false) return

        stopTrack()
        const timeout = setTimeout(() => {
            nextTrack()
            setPlayNextTrack(false)
        }, 250)
        return () => clearTimeout(timeout)
    }, [isPlayNextTrack])

    return (
        <>
            {activeTrack && (
                <StyledPlayerBarContainer data-cy-player-bar app={app}>
                    <Player
                        track={activeTrack}
                        status={status}
                        onStatusChange={(status: PlaybackStatus) =>
                            setStatus(status)
                        }
                        onNext={() => setPlayNextTrack(true)}
                    />
                    <StyledIcon>
                        <Icon.Close
                            onClick={stopTrack}
                            data-cy-hide-player-bar-button
                        />
                    </StyledIcon>
                </StyledPlayerBarContainer>
            )}
        </>
    )
}
